import { LitElement, html, css } from 'lit-element';

// This element is connected to the Redux store.
import { store } from './store.js';

// These are the actions needed by this element.
import {
  navigate,
  updateOffline,
  updateDrawerState,
  updateLayout
} from './actions/app.js';

import {
  anmelden,
  logout,
  validateRToken
} from './actions/auth.js'

import '@polymer/font-roboto/roboto.js';
import '@polymer/app-layout/app-layout.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-collapse';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/app-layout/app-drawer-layout/app-drawer-layout.js';
import '@polymer/app-layout/app-drawer/app-drawer.js';
import '@polymer/app-layout/app-scroll-effects/app-scroll-effects.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/app-layout/demo/sample-content.js';
import '@vaadin/vaadin-text-field/vaadin-text-field.js';
import '@vaadin/vaadin-text-field/vaadin-password-field.js';
import '@vaadin/vaadin-form-layout/vaadin-form-layout.js';
import '@vaadin/vaadin-form-layout/vaadin-form-item.js';
import './components/snack-bar.js';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query.js';
import { installOfflineWatcher } from 'pwa-helpers/network.js';
import { installRouter } from 'pwa-helpers/router.js';
import { updateMetadata } from 'pwa-helpers/metadata.js';
import '@polymer/paper-spinner/paper-spinner.js';
import { getUrlParams } from './util.js';
import './components/benutzerprofil-view.js';
import {until} from 'lit-html/directives/until.js';
import '@polymer/paper-dialog/paper-dialog.js';

import { resetPW } from './actions/useradmin.js';


class AdminApp  extends connect(store)(LitElement) {

  static get properties() {
    return {
      appTitle: { type: String },
      _page: { type: String },
      _drawerOpened: { type: Boolean },
      _snackbarOpened: { type: Boolean },
      _offline: { type: Boolean },
      _wideLayout: { type: Boolean },
      _currentUser: { type: String },
      _loginInfoOpened: { type: Boolean },
      _snackbarMessage: {type: String},
      _recoverLoading: {type: Boolean},
      _recovermode: {type: Boolean},
      _recovermodeRedux: {type: Boolean},
      _recoverValid: {type: Boolean},
      _recoverToken: {type: String},
    };
  }

  static get styles() {
    return [
      css`
        :host {
          --app-drawer-width: 256px;
          display: flex;
          flex-direction: column;

          --app-primary-color: #E91E63;
          --app-secondary-color: #595451;
          --app-dark-text-color: var(--app-secondary-color);
          --app-light-text-color: white;
          --app-section-even-color: #f7f7f7;
          --app-section-odd-color: white;

          --app-header-background-color: #699370;
          --app-header-text-color: #fff;
          --app-header-selected-color: var(--app-primary-color);

          --app-drawer-background-color: var(--app-secondary-color);
          --app-drawer-text-color: var(--app-light-text-color);
          --app-drawer-selected-color: #D9C954;
        }

        app-header {
          background-color: var(--app-header-background-color);
          color: var(--app-header-text-color);
          box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
        }

        .toolbar-top {
          background-color: var(--app-header-background-color);
        }

        [app-title] {
          font-weight:bold;
        }

        [main-title] {
          font-family: 'Roboto';
          font-weight: normal;
          margin-left: 24px;
        }

        paper-icon-button {
          --paper-icon-button-ink-color: white;
        }
        app-drawer-layout:not([narrow]) [drawer-toggle] {
          display: none;
        }

        .menu-btn {
          background: none;
          border: none;
          fill: var(--app-header-text-color);
          cursor: pointer;
          height: 44px;
          width: 44px;
        }

        .drawer-list {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding-top: 24px;
          background: var(--app-drawer-background-color);
          position: relative;
        }

        .drawer-list > a {
          display: block;
          text-decoration: none;
          color: var(--app-drawer-text-color);
          line-height: 40px;
          padding: 0 24px;
        }

        .drawer-list > a[selected] {
          color: var(--app-drawer-selected-color);
          border-left: 4px solid var(--app-drawer-selected-color);
          padding-left: 20px;
        }

        .drawer-list #currentUser {
          display: block;
          text-decoration: none;
          color: var(--app-drawer-text-color);
          line-height: 40px;
          padding: 50px 24px 10px 24px;
        }


        main {
          display: flex;
          flex-direction: column;
          flex: 5;
        }



        .page {
          display: none;
        }

        .page[active] {

          display: flex;
          flex-direction: column;
        }

        footer {
          padding: 24px;
          background: var(--app-drawer-background-color);
          color: var(--app-drawer-text-color);
          text-align: center;
        }

        /* Wide layout */
        @media (min-width: 768px) {
          app-header,
          .main-content,
          footer {
            margin-left: var(--app-drawer-width);
          }
          .menu-btn {
            display: none;
          }

          [main-title] {
            margin-left: 24px;
          }
        }
      `
    ];
  }

  stateChanged(state) {
    this._page = state.app.page;
    this._offline = state.app.offline;
    this._snackbarOpened = state.app.snackbarOpened;
    this._drawerOpened = state.app.drawerOpened;
    this._wideLayout = state.app.wideLayout;
    this._currentUser = state.auth.currentUser;
    this._snackbarMessage = state.app.snackbarMessage;
    this._recoverLoading = state.auth.recovery.isLoading;
    this._recoverValid = state.auth.recovery.isValid;
    this._recovermodeRedux = state.auth.recovery.active;
  }

  _logout(e) {
    console.log('logout called');
    store.dispatch(logout());
  }

  _isExpired(decoded) {
    const now = Date.now().valueOf() / 1000;
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
      console.log(`token expired: ${JSON.stringify(decoded)}`);
      return true;
    }
    return false;
  }

  isRecoverLink() {
    let params = getUrlParams(window.location.search);
    console.log('params: ',params);
    if (params['rid'] && params['rid'] != '') {
      console.log('recover mode');

      return params['rid'];
    }
    return '';
  }


  //${until(ridValidation(recoverTOKEN), html`<span>Loading...</span>`)}

  snackbar() {
    return html`
        <snack-bar ?active="${this._snackbarOpened}">
            ${this._snackbarMessage}
        </snack-bar>`;
  }

  render() {

    /* -------------------------------------- */
    /* RECOVER                                */
    /* -------------------------------------- */



    //let recoverTOKEN = this.isRecoverLink()
    if (this._recoverLoading /*|| this._recovermode*/  || this._recovermodeRedux) {

        return html`
                <style>

                body {
                  font-family: 'Roboto', sans-serif;
                  margin: 0;
                  background-color: #e5e5e5;
                }

                app-drawer {
                  z-index: 1;
                }

                </style>

                <app-header reveals effects="waterfall">
                    <app-toolbar>
                      <paper-icon-button icon="menu" @click="${this._menuButtonClicked}"></paper-icon-button>
                      <div main-title>Passwort vergeben</div>

                    </app-toolbar>
                </app-header>
                <app-drawer slot="drawer" .opened="${this._drawerOpened}" .persistent="${this._wideLayout}"
                    @opened-changed="${this._drawerOpenedChanged}" >
                  <app-toolbar app-title>UFS</app-toolbar>
                  <nav class="drawer-list">
                  </nav>
                </app-drawer>

                <main role="main" class="main-content">
                  ${ this._recoverLoading ?
                    html`<div style="display:flex;align-items:center;justify-content:center;height: calc(100vh - 220px);width:100%"><paper-spinner active></paper-spinner></div>`
                    :
                        this._recoverValid ?
                        html`<benutzerprofil-view class="page" token="${this._recoverToken}" ?active="${true}" ?recovermode="${true}"></benutzerprofil-view>` :
                        html`
                          <div>
                              <app-toolbar app-title>
                                <div main-title>Benutzerprofil </div>
                              </app-toolbar>
                          </div>
                          <p style="margin-left: 40px">Der Link ist leider abgelaufen oder nicht gültig. Bitte generieren Sie einen neuen Link und versuchen Sie es nochmal.


                        ` }
                </main>

                ${this.snackbar()}
              `;


    }


    /* ------------------------------------------------- */
    /* LOGIN / APP                                       */
    /* ------------------------------------------------- */

    //let cookie = this.getCookie('jwt');
    if (this._currentUser=='' || this._currentUser==null ||
      this._isExpired(this._currentUser )) {
    //if (cookie == null) {
      return html`
        <login-view></login-view>
      `
    }
    console.log("_currentUser=",this._currentUser);
    return html`
    <style>

      body {
        font-family: 'Roboto', sans-serif;
        margin: 0;
        background-color: #e5e5e5;
      }

      app-drawer {
        z-index: 1;
      }

    </style>

      <app-header reveals effects="waterfall">
          <app-toolbar>
            <paper-icon-button icon="menu" @click="${this._menuButtonClicked}"></paper-icon-button>
            <div main-title>Administration</div>

            <paper-icon-button icon="icons:power-settings-new" @click=${this._logout}></paper-icon-button>
          </app-toolbar>
      </app-header>

      <app-drawer slot="drawer" .opened="${this._drawerOpened}" .persistent="${this._wideLayout}"
          @opened-changed="${this._drawerOpenedChanged}" >
        <app-toolbar app-title>UFS</app-toolbar>
        <nav class="drawer-list">
          <a href="/" ?selected="${this._page === 'dashboard'}"><iron-icon icon="icons:home"></iron-icon> Dashboard</a>
          <a href="/firmen" ?selected="${this._page === 'firmen' || this._page === 'firmaedit'}"><iron-icon icon="social:location-city"></iron-icon>  Firmen</a>
          <a href="/meldungen" ?selected="${this._page === 'meldungen'}"><iron-icon icon="icons:view-list"></iron-icon> Meldungen</a>
          <a href="/auswertungen" ?selected="${this._page === 'auswertungen'}"><iron-icon icon="icons:timeline"></iron-icon> Auswertungen</a>
          <a href="/rechnungen" ?selected="${this._page === 'rechnungen'}"><iron-icon icon="icons:euro-symbol"></iron-icon> Rechnungen</a>
          <a href="/benutzerverwaltung" ?selected="${this._page === 'benutzerverwaltung'}"><iron-icon icon="social:people"></iron-icon> Benutzerverwaltung</a>
          <br>
          <a href="/benutzerprofil" ?selected="${this._page === 'benutzerprofil'}"><iron-icon icon="icons:perm-identity"></iron-icon>
                    ${this._currentUser != null
                        ? html`${this._currentUser.id}`
                        : ''
                    }
          </a>

        </nav>

      </app-drawer>


      <!-- Main content -->
      <main role="main" class="main-content">
        <dashboard-view class="page" ?active="${this._page === 'dashboard'}" ></dashboard-view>
        <firmen-view class="page" ?active="${this._page === 'firmen'}"></firmen-view>
        <firmaedit-view class="page" ?active="${this._page === 'firmaedit'}"></firmaedit-view>
        <meldungen-view class="page" ?active="${this._page === 'meldungen'}"></meldungen-view>
        <rechnungen-view class="page" ?active="${this._page === 'rechnungen'}"></rechnungen-view>
        <auswertungen-view class="page" ?active="${this._page === 'auswertungen'}"></auswertungen-view>
        <useradmin-view class="page" ?active="${this._page === 'benutzerverwaltung'}"></useradmin-view>
        <benutzerprofil-view class="page" ?active="${this._page === 'benutzerprofil'}"></benutzerprofil-view>
        <error404-view class="page" ?active="${this._page === 'error404'}"></error404-view>
      </main>

      ${this.snackbar()}

    `;
  }

  constructor() {
    super();
    // To force all event listeners for gestures to be passive.
    // See https://www.polymer-project.org/3.0/docs/devguide/settings#setting-passive-touch-gestures
    setPassiveTouchGestures(true);
    this._currentUser = null;
    this._loginInfoOpened =false;
    this._recoverLoading = false;
    this._recoverValid = false;
    this._recoverToken = '';
    this.appTitle = 'UFS';
  }

  firstUpdated() {
    //installRouter((location) => store.dispatch(navigate(decodeURIComponent(location.pathname))));
    installRouter((location, event) => {
      // Only scroll to top on link clicks, not popstate events.
      if (event && event.type === 'click') {
        window.scrollTo(0, 0);
      }
      store.dispatch(navigate(decodeURIComponent(location.pathname) ))
    });

    installOfflineWatcher((offline) => store.dispatch(updateOffline(offline)));
    installMediaQueryWatcher(`(min-width: 768px)`,
        (matches) => store.dispatch(updateLayout(matches)));

    //
    let recoverTOKEN = this.isRecoverLink();

    if (recoverTOKEN != '') {

      store.dispatch(validateRToken(recoverTOKEN)).then( result => {
        this._recoverToken = recoverTOKEN;
        //this._recovermode = true;
      });
    }
  }

  updated(changedProps) {
    if (changedProps.has('_page')) {
      const pageTitle = this.appTitle + ' - ' + this._page;
      updateMetadata({
        title: pageTitle,
        description: pageTitle
        // This object also takes an image property, that points to an img src.
      });
    }
  }

  _menuButtonClicked() {
    store.dispatch(updateDrawerState(true));
  }

  _drawerOpenedChanged(e) {
    store.dispatch(updateDrawerState(e.target.opened));
  }



}





class LoginView extends connect(store)(LitElement) {

  constructor() {
    super();
    this.username = null;
    this.password = null;
    this._loginInfoOpened = false;
    this._isLoading = true;
    this._loginDenied = true;  // falsches PW
  }

  static get properties() {
    return {
      auth_token: { type: String },
      username: { type: String },
      password: { type: String },
      _loginInfoOpened: { type: Boolean },
      _isLoading: { type: Boolean },
      _loginDenied: { type: Boolean }
    };
  }

  static get styles() {
    return [
      css`
        :host {
          --app-drawer-width: 256px;
          display: block;

          --app-primary-color: #E91E63;
          --app-secondary-color: #595451;
          --app-dark-text-color: var(--app-secondary-color);
          --app-light-text-color: white;
          --app-section-even-color: #f7f7f7;
          --app-section-odd-color: white;

          --app-header-background-color: #699370;
          --app-header-text-color: #fff;
          --app-header-selected-color: var(--app-primary-color);

          --app-drawer-background-color: var(--app-secondary-color);
          --app-drawer-text-color: var(--app-light-text-color);
          --app-drawer-selected-color: #D9C954;
        }

        app-header {
          background-color: var(--app-header-background-color);
          color: var(--app-header-text-color);
          box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
        }

        .toolbar-top {
          background-color: var(--app-header-background-color);
        }

        [app-title] {
          font-weight:bold;
        }

        [main-title] {
          font-family: 'Roboto';
          font-weight: normal;
          margin-left: 24px;
        }


        /* Workaround for IE11 displaying <main> as inline */
        main {
          display: block;
        }

        .main-content {
          /* padding-top: 64px;*/
          min-height: 100vh;
        }

        .page {
          display: none;
        }

        .page[active] {
          display: block;
        }

        footer {
          padding: 24px;
          background: var(--app-drawer-background-color);
          color: var(--app-drawer-text-color);
          text-align: center;
        }

        /* Wide layout */
        @media (min-width: 768px) {
          app-header,
          .main-content,
          footer {
            margin-left: var(--app-drawer-width);
          }
          .menu-btn {
            display: none;
          }

          [main-title] {
            margin-left: 24px;
          }
        }
      `
    ];
  }

  toggleLoginInfo() {
    console.log('toggle clicked',this._loginInfoOpened);
    this._loginInfoOpened = !this._loginInfoOpened;
  }

  onUsernameChange(e) {
    //let val = this.shadowRoot.getElementById("UsernameField").value
    //console.log('userNameChange',e);
    console.log('userNameChange value',e.target.value);
    this.username = e.target.value;
  }

  onPasswordChange(e) {
    console.log('passwordChange value',e.target.value);
    this.password = e.target.value;
  }

  onAnmelden() {
    store.dispatch(anmelden(this.username, this.password));
  }

  pwReset() {
    if (!this.username || this.username=="") {
      // Dialog dass Username fehlt
      this.shadowRoot.getElementById('benutzernameLeerDialog').open();
    } else {
      this.shadowRoot.getElementById('pwResetConfirmDialog').open();

    }
    console.log("PW reset");
  }


  stateChanged(state) {
    this._isLoading = state.auth.isLoading;
    this._username = state.auth.username;
    this._loginDenied = state.auth.loginDenied;

  }

  shortcutListener(e) {
    if (e.key === 'Enter') {
      this.onAnmelden();
    }
  }


  render() {
      return html`
        <style>

        body {
          font-family: 'Roboto', sans-serif;
          margin: 0;
          background-color: #e5e5e5;
        }

        header {

          min-height: calc(100vh - 64px);
          padding: 0 0px;
          background-image: url('/images/landscape-1920.jpg');
          background-repeat: no-repeat;
          background-size: cover;

          display: flex;
          justify-content: center;

        }

        #logincard {
            width:100%;
            background:rgba(255, 255, 255, 0.7);
        }

        vaadin-text-field {
          max-width: 100%;
        }

        @media (min-width: 768px) {
          #logincard {
            width:440px;
            background:rgba(255, 255, 255, 0.7);
            h3 {
              font-size: 0.8em;
            }
          }
        }

        #logincard  h3  {
              font-weight: normal;

            }


        </style>
        <app-header-layout>
          <app-header fixed style="margin-left:0">
            <app-toolbar>
              <div main-title ></div>

            </app-toolbar>
          </app-header>
          <header>

            <paper-card id="logincard" image="./images/logo.png" alt="UFS">
              <div class="card-content">
                  ${this._loginDenied
                      ? html`<span style="color:red">Benutzername oder Passwort falsch.</span>`
                      : html`Bitte geben Sie Ihre Zugangsdaten ein:`
                  }
                <vaadin-form-layout>
                  <vaadin-text-field id="UsernameField" label="Benutzername" maxlength="32" autoselect
                    @change="${this.onUsernameChange}" .value="${this.username}" placeholder="Benutzername eingeben"></vaadin-text-field>
                  <vaadin-password-field id="PasswordField" label="Passwort" maxlength="32"
                    @change="${this.onPasswordChange}"  placeholder="Passwort eingeben" value="" @keyup="${this.shortcutListener}">
                  </vaadin-password-field>
                </vaadin-form-layout>
              </div>
              <div class="card-actions">
                ${this._isLoading
                      ? html`<paper-spinner active></paper-spinner>`
                      : html`<paper-button ?disabled=${this.username==null || this.username==''} @click=${this.onAnmelden}><iron-icon icon="arrow-forward"></iron-icon>Anmelden</paper-button>`
                }

                <!--
                <p>By continuing, you agree to Home's Terms of service, Privacy policy and Cookie use.</p>
                -->
                <paper-icon-button icon=${!this._loginInfoOpened ? 'expand-more': 'expand-less'} @click="${this.toggleLoginInfo}" title="more info"  style="float:right;">
                </paper-icon-button>
                <iron-collapse id="more-info" ?opened=${this._loginInfoOpened} >
                  <h3>Zugangsdaten vergessen?</h3>
                  <p>Sie können Ihr Passwort über einen Link den wir per Email versenden zurücksetzen. </p>
                  <!--paper-button ><iron-icon icon="arrow-forward"></iron-icon> Benutzername vergessen</paper-button-->
                  <paper-button @click="${this.pwReset.bind(this)}"><iron-icon icon="arrow-forward"></iron-icon> Passwort zurücksetzen</paper-button>
                </iron-collapse>

              </div>
            </paper-card>
          </header>
        </app-header-layout>



        <paper-dialog id="benutzernameLeerDialog"  name="benutzernameLeerDialog"  modal>


          <h2>Bitte tragen Sie zuerst Ihren Benutzernamen ein.</h2>

          <div class="buttons">
            <paper-button  theme="error" dialog-confirm autofocus >
              OK
            </paper-button>

          </div>
        </paper-dialog>

        <paper-dialog id="pwResetConfirmDialog"    modal>


          <h2>Passwort wirklich zurücksetzen?</h2>

          <div class="buttons">
          <paper-button  dialog-dismiss style="margin-right:10px" >
              Abbrechen
            </paper-button>
            <paper-button  theme="error" dialog-confirm autofocus
              @click="${() => {
                store.dispatch(resetPW(this.username) ).then((r)=>{
                  if (r.result && r.result.status == 0) {

                      this.shadowRoot.getElementById('pwResetFailedDialog').open();
                  } else if (r.result &&  r.result.status == 1) {
                      this.shadowRoot.getElementById('pwResetOKDialog').open();


                  }

                })
              }
              }">
              OK
            </paper-button>

          </div>
        </paper-dialog>

        <paper-dialog id="pwResetOKDialog"    modal>


          <h2>Ein Reset-Link wurde an Ihre Email gesandt (Gültigkeit 30min).</h2>

          <div class="buttons">

            <paper-button  theme="error" dialog-confirm autofocus>
              OK
            </paper-button>

          </div>
        </paper-dialog>

        <paper-dialog id="pwResetFailedDialog"    modal>


          <h2>Der Benutzername '${this.username}' wurde nicht gefunden.</h2>

          <div class="buttons">
            <paper-button  theme="error" dialog-confirm autofocus>
              OK
            </paper-button>

          </div>
        </paper-dialog>

      `
    }


}



customElements.define('login-view', LoginView);
customElements.define('admin-app', AdminApp);
